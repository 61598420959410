import AttendanceBaseService from './base/AttendanceBaseService';

export class StaffAttendanceApi extends AttendanceBaseService {
    constructor() {
        super("attRecordStatistics");
    }

    /**
     * 获取全部员工考勤统计列表
    */
    public getAllAttendanceList(params: any): Promise<any> {
        return this.request('list', this.rest.METHOD_GET, params);
    }

    /**
     * 获取某个员工考勤统计列表
    */
     public getStaffAttendanceList(params: any): Promise<any> {
        return this.request('user', this.rest.METHOD_GET, params);
    }

    /**
     * 获取待接待访客列表
    */
    //  public addVisitor(params: any): Promise<any> {
    //     return this.request('save', this.rest.METHOD_POST, params);
    // }

    /**
     * 获取访客信息
    */
    //  public visitorDetails(params: any): Promise<any> {
    //     return this.request('detail', this.rest.METHOD_GET, params);
    // }

}