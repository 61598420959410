import CoreService from '@/api/base/CoreService';
export default class AttendanceBaseService extends CoreService {
    constructor(resource: string) {
        super({
            apiBaseURL:"attapi",
            name: "",
            resource: "attendance/"+resource,
            version:""
        });
    }
}