import { StaffAttendanceApi } from '@/api/staffattendance/StaffAttendanceApi';

export class StaffAttendanceService {
    private api = new StaffAttendanceApi();

    //获取全部员工考勤统计列表
    public getAllAttendanceList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getAllAttendanceList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //获取某个员工考勤统计列表
    public getStaffAttendanceList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getStaffAttendanceList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //添加访客人员
    // public addVisitor(params: any): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.api.addVisitor(params).then((res: any) => {
    //             resolve(res.data);
    //         }).catch((err: any) => {
    //             reject(err);
    //         });
    //     });
    // }

    //访客人员信息
    // public visitorDetails(params: any): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.api.visitorDetails(params).then((res: any) => {
    //             resolve(res.data);
    //         }).catch((err: any) => {
    //             reject(err);
    //         });
    //     });
    // }
}