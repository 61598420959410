
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import { StaffAttendanceService } from "@/services/staffattendance/StaffAttendanceService";
import SystemUtils from "@/utils/SystemUtils";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import moment from "moment";

export default defineComponent({
  name: "AttendanceStatisticsDetails",

  components: {
    Header,
  },
  setup() {
    const signInList = reactive({
      data: new Array(),
    });

    const listNum = reactive({
      normalNum: 0,
      abnormalNum: 0,
    });
    const query = useRoute().query;

    let page = 1;
    let size = 10;

    const service = new StaffAttendanceService();

    const user = SystemUtils.loginUser;

    let nowDate = new Date();

    let nowMonth = nowDate.getMonth();
    let nowYear = nowDate.getFullYear();

    let startDate = "";
    let endDate = "";

    //nowDate.getMonth() 该方法获取的月份为 0-11 ， 0代表1月份以此类推
    if (nowMonth == 0) {
      startDate = nowYear - 1 + "-12-24";
    } else {
      startDate = nowYear + "-" + nowMonth + "-24";
    }

    endDate = nowYear + "-" + (nowMonth + 1) + "-23";

    const loadList = () => {
      const params = {
        userId: query.userId,
        page: page,
        size: size,
        startDate: startDate,
        endDate: endDate,
      };
      service
        .getStaffAttendanceList(params)
        .then(
          (result: any) => {
            if (result) {
              listNum.normalNum = result.normalNum;
              listNum.abnormalNum = result.abnormalNum;

              signInList.data = result.list;
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const router = useRouter();

    const goInto = (id: string) => {
      router.push({
        name: "SignInDetail",
        params: {
          id: id,
        },
      });
    };

    const initDate = () => {
      page = 1;
      loadList();
    };

    const bindChangeStartDate = (ev: any) => {
      startDate = moment(ev.detail.value).format("YYYY-MM-DD");
      initDate();
    };

    const bindChangeEndDate = (ev: any) => {
      endDate = moment(ev.detail.value).format("YYYY-MM-DD");
      initDate();
    };

    onMounted(() => {
      initDate();
    });

    return {
      signInList,
      goInto,
      initDate,
      bindChangeStartDate,
      bindChangeEndDate,
      query,
      listNum,
      startDate,
      endDate,
    };
  },
});
